<template>
  <a-modal v-model="visible" :title="title"
           :mask-closable="false"
           @ok="handleOk" :confirmLoading="loading">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      v-bind="formItemLayout"
    >
      <a-form-item label="资料名称">
        <a-input placeholder="请输入" v-model.trim="form.file_title"/>
      </a-form-item>
      <a-form-item label="上传本地文件">
        <Uploader v-model="form.file_id"
                  :custom-file-list="fileLists"
                  :multiple="false"
                  :count="1"
                  @uploadLoainng="onUploadLoainng">
          <a-button> 上传</a-button>
        </Uploader>
      </a-form-item>
      <a-form-item v-if="trainingType === 2" label="选择阶段">
        <a-select placeholder="请选择阶段" class="w700" v-model="form.stage">
          <a-select-option v-for="item in stageList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { fileListAdd, fileListModify, fileListshow } from '@/api/config';
import { mapState } from 'vuex';

export default {
  name: 'addSurveyLayer',
  components: {
    Uploader: () => import('@/components/upload/Uploader'),
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
      stageList: (state) => state.Offline.stage,
    }),
  },
  data() {
    return {
      visible: false,
      form: {
        file_title: '',
        file_id: '',
        stage: '',
      },
      formItemLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      isEdit: false,
      fileListAdd,
      fileListModify,
      fileLists: [],
      title: '添加资料',
      loading: false,
    };
  },
  methods: {
    uploadSuccess(data) {
      this.form.file_id = data.file_resource_id;
      this.$message.success('上传成功');
    },
    openMe(data) {
      this.visible = true;
      this.title = (data?.id && data?.id > 0) ? '编辑资料' : '添加资料';
      if (data) {
        this.isEdit = true;
        this.form.id = data.id;
        this.fileListshow(data.id);
        return;
      }
      this.form = {};
      this.fileLists = [];
    },
    handleOk() {
      let { file_title, file_id, stage } = this.form;
      if (!file_title) {
        this.$message.error('请输入资料名称！');
        return;
      }
      if (!file_id) {
        this.$message.error('请上传本地文件！');
        return;
      }
      if (this.trainingType === 2 && !stage) {
        this.$message.error('请选择阶段！');
        return;
      }
      let str = this.isEdit ? 'fileListModify' : 'fileListAdd';
      this.form.training_id = !this.isEdit ? this.$route.query.id : '';
      this.saveData(str);
    },
    // 详情
    async fileListshow(id) {
      const { data } = await fileListshow({ id });
      this.form = {
        id,
        file_title: data.title,
        file_id: data.file_id,
        stage: data.stage,
      };
      this.fileLists = data.resource_files;
    },
    async saveData(str) {
      this.loading = true;
      const data = await this[str](this.form).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.$emit('saveSuccess');
        this.isEdit = false;
        this.clear();
      }
    },
    clear() {
      this.form = {
        file_title: '',
        file_id: '',
      };
      this.visible = false;
    },
    onUploadLoainng(value) {
      this.loading = value;
    },
  },
};
</script>

<style scoped>
</style>
